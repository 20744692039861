.no-scroll {
    overflow: hidden;
}

.navbar-container {
    width: 100%;
    z-index: 1000;
    top: 0;
    position: relative;
}

.main-navbar {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fff;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
    margin-right: 20px;
}

.nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-links ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-item {
    position: relative;
}

.nav-links li {
    margin: 0 10px;
}

.nav-links a {
    color: #0a4f88;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.nav-links a:hover {
    color: #0a4f88;
}

.dropdown-menu {
   background-color: #fff;
   position: absolute;
   flex-direction: column;
   width: 200px;
   border: 1px solid #ccc;
   border-radius: 3px;
}
.dropdown-menu li{
    margin: 4px;
    border-bottom: 1px solid #e4e4e4;
    padding: 5px;
}
.dropdown-icon {
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.mobile-menu-icon {
    display: none;
    cursor: pointer;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        background: #fff;
        position: absolute;
        top: 80px;
        /* Adjust this value based on your navbar height */
        left: 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        z-index: 1000;
    }

    .nav-links.open {
        display: flex;
    }

    .nav-links ul {
        flex-direction: column;
    }

    .nav-links li {
        padding: 10px;
    }

    .mobile-menu-icon {
        display: block;
        position: absolute;
        right: 10px;
    }

    .main-navbar {
        justify-content: space-between;
    }

    .submenu {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        box-shadow: none;
    }
}