/* components/SocialMedia.css */
.social-media {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 1px;
}

.social-media a {
    color: #fff;
    font-size: 24px;
    text-decoration: none;
    background-color: rgba(10, 79, 136, 0.89);
    /* Semi-transparent background color */
    padding: 10px;
    border-radius: 50px;
}

.social-media a:hover {
    color: #0073e6;
    /* Change hover color as needed */
}