/* About.css */

.about-container {
    position: relative;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.about-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.about-text {
    position: absolute;
    color: #0a4f88;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
}

@media (max-width: 600px) {
    .about-container {
        height: 200px;
    }

    .about-text {
        font-size: 1.2rem;
        padding: 3px 7px;
    }
}