.legal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* This makes the container take the full viewport height */
    padding: 10px;
    /* Add some padding */
}

.legal {
    margin: 20px;
    /* Adjust the margin as needed */
    border: 2px solid #0a4f88;
    /* Border color */
    max-width: 100%;
    /* Ensure the image does not exceed its container's width */
    height: auto;
    /* Maintain the aspect ratio */
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .legal {
        margin: 10px;
        /* Reduced margin for smaller screens */
    }
}

@media (max-width: 480px) {
    .legal-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .legal {
        margin: 5px;
        width: 100%;
        /* Ensure full width for small screens */
    }
}