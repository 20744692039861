.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.kw-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    flex-wrap: wrap;
}

.kw-content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.kw-image {
    width: 100%;
    max-width: 300px;
    margin: 0 20px;
}

.kw-text {
    max-width: 600px;
    text-align: center;
}

.kw-text h1 {
    margin-bottom: 10px;
    color: #0a4f88;
}

.kw-text ul {
    list-style-type: none;
    padding: 0;
}

.kw-text li {
    margin-bottom: 5px;
    padding-left: 15px;
    text-indent: -15px;
    color: gray;
    font-weight: 500;
}

.kw-text li::before {
    content: "•";
    color: green;
    padding-right: 10px;
}

.reverse .kw-content {
    flex-direction: column-reverse;
}

/* Responsive Styles */
@media (min-width: 768px) {
    .kw-container {
        flex-wrap: nowrap;
    }

    .kw-content {
        flex-direction: row;
        text-align: left;
    }

    .reverse .kw-content {
        flex-direction: row-reverse;
    }

    .kw-text {
        text-align: left;
    }
}

@media (min-width: 1024px) {
    .kw-image {
        width: 300px;
    }

    .kw-content {
        flex-direction: row;
    }

    .reverse .kw-content {
        flex-direction: row-reverse;
    }
}