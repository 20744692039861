body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
}

.main-content {
    height: 100vh;
    background-color: #f5f5f5;
    overflow: hidden;
}

.slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Maintain aspect ratio and cover the entire slide */
}

.slide-content {
    position: absolute;
    top: 10%;
    left: 5%;
    text-align: left;
    color: white;
    width: 90%;
}

.slide-content h2 {
    font-size: calc(2.5vw + 2.5vh + 1.5vmin);
    margin-bottom: 10px;
}

.slide-content p {
    font-size: calc(1vw + 1vh + 0.5vmin);
    margin-bottom: 20px;
    color: white;
    font-weight: bold;
    margin-top: 10%;
}

.slide-content button {
    font-size: calc(1vw + 1vh + 0.5vmin);
    padding: 10px 20px;
    background-color: #ff9900;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.slide-content button:hover {
    background-color: #e68a00;
}
/* Why Choose Us */
.why-choose-us {
    padding: 20px;
    background: #fff;
}

.why-choose-us-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.why-choose-us-image {
    width: 100%;
    max-width: 300px;
    margin: 10px;
}

.why-choose-us-text {
    width: 100%;
    max-width: 600px;
    margin: 10px;
}

.why-choose-us-text h2 {
    font-size: 2em;
    color: #0a4f88;
    margin-bottom: 20px;
}

.why-choose-us-text h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: goldenrod;
}

.why-choose-us-text p {
    font-size: 1em;
    margin-bottom: 20px;
    color: gray;
    font-weight: 700;
}

/* Features section */
.features {
    padding: 20px;
    background-color: #eeeeee;
    text-align: center;
}

.features h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #8B0000;
}

.features-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.features-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    justify-content: space-between;
}

.feature {
    margin-bottom: 20px;
    text-align: left;
}

.feature i {
    font-size: 2em;
    margin-bottom: 10px;
    color: #006400;
}

.feature h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #8B0000;
}

.feature p {
    font-size: 1em;
    color: #333;
}

.feature-image {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    margin: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
}

.feature-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

/* General Responsive Styles */
@media (max-width: 795px) {
    .slide-content h2 {
        font-size: calc(2vw + 2vh + 1.5vmin);
    }

    .slide-content p {
        font-size: calc(1vw + 1vh + 0.5vmin);
    }

    .main-content {
        height: 60vh;
    }
}

@media (max-width: 603px) {
    .main-content {
        height: 50vh;
    }

    .slide-content {
        top: 0%;
        left: 5%;
        text-align: center;
    }

    .slide-content h2,
    .slide-content p {
        text-align: center;
    }
}

@media (max-width: 411px) {
    .main-content {
        height: 40vh;
    }

    .slide-content h2 {
        font-size: calc(1.5vw + 1.5vh + 1vmin);
    }

    .slide-content p {
        font-size: calc(0.8vw + 0.8vh + 0.4vmin);
    }

    .why-choose-us-image,
    .feature-image {
        display: none;
    }

    .features-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .features-column {
        width: 100%;
        max-width: none;
        margin-bottom: 20px;
    }
}

@media (max-width: 280px) {
    .main-content {
        height: 30vh;
    }

    .slide-content h2 {
        font-size: calc(1vw + 1vh + 0.5vmin);
    }

    .slide-content p {
        font-size: calc(0.5vw + 0.5vh + 0.3vmin);
    }

    .why-choose-us-image,
    .feature-image {
        display: none;
    }

    .features-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .features-column {
        width: 100%;
        max-width: none;
        margin-bottom: 20px;
    }
}
.faqs {
    padding: 40px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.faqs h2 {
    font-size: 2em;
    color: #0a4f88;
    /* Adjusted to your theme color */
    text-align: center;
    margin-bottom: 20px;
}

.faqs-content {
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    font-size: 1.1em;
}

.faqs-content h3 {
    font-size: 1.5em;
    color: #333;
    margin-top: 20px;
}

.faqs-content p {
    margin-top: 10px;
    color: #555;
}

@media (max-width: 768px) {
    .faqs h2 {
        font-size: 1.5em;
    }

    .faqs-content h3 {
        font-size: 1.2em;
    }

    .faqs-content p {
        font-size: 1em;
    }
}