.footer {
    background-color: #2c3e50;
    /* Dark background color */
    color: #ecf0f1;
    /* Light text color */
    padding: 20px 0;
    /* Padding around the text */
    text-align: center;
    /* Center align text */
    /* Fix the footer at the bottom */
    bottom: 0;
    width: 100%;
    position: fixed;
    /* Full width */
}

.footer-content {
    max-width: 1200px;
    /* Max width for content alignment */
    margin: 0 auto;
    /* Center align content */
    padding: 0 20px;
    /* Side padding for content */
    font-size: 14px;
    /* Font size */
}

.footer-content p {
    margin: 0;
    /* Remove default margin */
    line-height: 1.6;
    /* Line height */
}