.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    flex-wrap: wrap;
}

.contact-info {
    flex: 1;
    padding: 20px;
    min-width: 300px;
}

.contact-info h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
    color: #0a4f88;
}

.contact-info h2 {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
    color: #0a4f88;
}

.contact-info p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 10px;
    color: gray;
}

.contact-image {
    flex: 1;
    text-align: center;
    min-width: 300px;
}

.contact-image img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    background: #fff;
}

.contact-map-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    flex-wrap: wrap;
}

.contact-info-side {
    flex: 1;
    padding-left: 20px;
    min-width: 300px;
    order: 2;
}

.map-container {
    flex: 2;
    min-width: 300px;
    margin-top: 20px;
    order: 1;
}

.contact-info-side p {
    margin-top: 20px;
    color: gray;
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
        align-items: center;
    }

    .contact-image {
       display: none;
    }

    .contact-info h1 {
        font-size: 1.8em;
    }

    .contact-info h2 {
        font-size: 16px;
    }

    .contact-info p {
        font-size: 1.1em;
    }

    .contact-map-section {
        flex-direction: column;
        align-items: center;
    }

    .map-container,
    .contact-info-side {
        width: 100%;
        order: 1;
    }

    .contact-info-side {
        margin-top: 20px;
        order: 2;
    }
}

@media (max-width: 480px) {
    .contact-info h1 {
        font-size: 1.5em;
    }

    .contact-info h2 {
        font-size: 14px;
    }

    .contact-info p {
        font-size: 1em;
    }

    .contact-image img {
        width: 100%;
    }
}